import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width={100}
    height={100}
    {...props}
  >
    <path d="M18.137 3a1.009 1.009 0 0 0-.696.29l-6.023 5.976a1.003 1.003 0 0 0-.008 1.414L25.645 25.03 11.297 39.266a.996.996 0 0 0-.008 1.414l5.977 6.023a.996.996 0 0 0 1.414.008l21.086-20.914c.39-.39.394-1.024.004-1.418L18.86 3.297A.991.991 0 0 0 18.136 3Z" />
  </svg>
)

export default SvgComponent
