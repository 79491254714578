import './App.css';
import './styles/Formatting.css'
import './styles/Colors.css'
import './styles/Text.css'
import React, {Component} from "react";
import HeroSection from "./Sections/HeroSection";
import Navbar from "./components/Navbar";
import RecommendationsSection from "./Sections/RecommendationsSection";
import Section3 from "./Sections/ProfileSection";
import ExperiencesSection from "./Sections/ExperiencesSection";
import Footer from "./Sections/Footer";
import GradeSection from "./Sections/GradeSection";

class MobileApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scrollPosition: 0,
            mobile: window.innerWidth <= 760,
            scrollHighlight: 1,
        }
        this.section1Ref = React.createRef();
        this.section2Ref = React.createRef();
        this.section3Ref = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = (e) => {
        const winScroll =
            document.documentElement.scrollTop
        this.setState({
            scrollPosition: winScroll,
        })
        if (e.target.scrollTop > this.section1Ref.current.offsetTop && e.target.scrollTop < (this.section2Ref.current.offsetTop - 300)) {
            this.setState({scrollHighlight: 1})
            console.log('a')
        }
        if (e.target.scrollTop > (this.section2Ref.current.offsetTop - 300) && e.target.scrollTop < this.section3Ref.current.offsetTop - 300) {
            this.setState({scrollHighlight: 2})
            console.log('b')
        }
        if (e.target.scrollTop > this.section3Ref.current.offsetTop - 300) {
            this.setState({scrollHighlight: 3})
            console.log('c')
        }
    }

    scrollToSection1 = () => {
        this.section1Ref.current.scrollIntoView({behavior: 'smooth'})
    }

    scrollToSection2 = () => {
        this.section2Ref.current.scrollIntoView({behavior: 'smooth'})
    }

    scrollToSection3 = () => {
        this.section3Ref.current.scrollIntoView({behavior: 'smooth'})
    }

    render() {
        return (
            <>
                {console.log(this.state.scrollPosition)}
                <Navbar mobile={true} scrollPosition={this.state.scrollPosition}/>
                <div onScroll={this.listenToScroll.bind(this)} style={{flex: 1, overflow: 'scroll'}} className='ph-16'>
                    <div ref={this.section1Ref} style={{minHeight: "90vh"}}>
                        <HeroSection mobile={true} scrollPosition={this.state.scrollPosition}/>
                    </div>
                    <div ref={this.section2Ref} style={{minHeight: "80vh", overflowX: 'hidden'}}>
                        <RecommendationsSection mobile={true}/>
                    </div>
                    <div className='col-jc' style={{minHeight: '90vh'}} ref={this.section3Ref}>
                        <ExperiencesSection scrollPosition={this.state.scrollPosition}
                                            mobile={true}
                                            offsetTop={0}/>
                    </div>
                    <div className='col-jc' style={{minHeight: '90vh'}}>
                        <GradeSection scrollPosition={this.state.scrollPosition}
                                      offsetTop={0}
                                      mobile={true}
                        />
                    </div>
                    <div ref={this.section3Ref} style={{minHeight: "75vh"}}>
                        <Section3 mobile={true}/>
                    </div>
                    <Footer
                        mobile={true}
                    />
                </div>
                {/*<MobileNavMenu*/}
                {/*    scrollToSection1={this.scrollToSection1}*/}
                {/*    scrollToSection2={this.scrollToSection2}*/}
                {/*    scrollToSection3={this.scrollToSection3}*/}
                {/*    scrollHighlight={this.state.scrollHighlight}/>*/}
            </>
        )
    }
}

export default MobileApp;
