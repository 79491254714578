import React, {Component} from "react";
import {motion} from "framer-motion";
import phones from "../img/2phones.png";

class LearnMoreSection extends Component {
    render() {
        return (
            <div
                className={this.props.mobile ? 'text-right col-ac white-100 lines' : 'text-right row-ac white-100 lines'}
                style={{
                    paddingTop: this.props.mobile && '12vh',
                    padding: this.props.mobile ? '40px 16px' : '140px 80px 140px 0',
                    marginRight: !this.props.mobile ? -80 : -16,
                    marginTop: '10vh',
                    marginLeft: this.props.mobile && -16
                }}>
                <motion.div
                    initial={{opacity: 0, transform: 'scale(1) translateY(5%)'}}
                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                    transition={{default: {ease: "linear"}, delay: .4}}
                    style={{flex: 1, marginLeft: !this.props.mobile && 100}} className='card-grid col-ac'>
                    {/*<div className='row-ac-jc'>*/}
                    {/*    <img className='border-animation-lg mt-40 mr-12' src={festival}*/}
                    {/*         style={{maxWidth: this.props.mobile ? '40%' : 300}}/>*/}
                    {/*    <img className='border-animation-lg ml-12' src={convention}*/}
                    {/*         style={{maxWidth: this.props.mobile ? '40%' : 300}}/>*/}
                    {/*</div>*/}
                    {/*<div className='row-ac-jc'>*/}
                    {/*    <img className='border-animation-lg mt-28' src={concert}*/}
                    {/*         style={{maxWidth: this.props.mobile ? '40%' : 300}}/>*/}
                    {/*</div>*/}
                    {this.props.mobile ?
                        <img src={phones} className='w-80p mt-100'/>
                        :
                        <img src={phones} className='w-60p mt-100 phones'/>
                    }
                </motion.div>
                <motion.div
                    initial={{opacity: 0, transform: 'scale(1) translateY(5%)'}}
                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                    transition={{default: {ease: "linear"}, delay: .2}}
                    style={{flex: 1}} className={this.props.mobile ? 'mt-20 text-center' : 'ml-100'}>
                    <h1 className='font2 heading mb-20' style={{fontSize: this.props.mobile && 32}}>
                        LEARN MORE<br/> ABOUT YOU
                        <span className='heading-slash' style={{fontSize: this.props.mobile && 32}}>//</span>
                    </h1>
                    <div className='white-80'>
                        <p className={this.props.mobile ? 'body3' : 'body1'}>
                            WeedWise provides a <span className='highlight'> detailed breakdown of your weed preferences</span> including your most
                            commonly used strains, your favorite brands, product types, and your most preferred effects.
                            Learn more about what you most enjoy, and how to find it.
                        </p>
                    </div>
                </motion.div>
            </div>
        );
    }
}

export default LearnMoreSection;
