import React, {Component} from "react";
import {FOOTER_LINKS_1, FOOTER_LINKS_2, FOOTER_LINKS_3} from "../dummy";
import navlogo from "../img/brand/logo/logo.png";
import navlogodark from "../img/brand/logo/CONVRG White Logo_04.01.2019_AO.png";

const DISABLED_ROUTES = ["/login", "/checkout"]

class Footer extends Component {

    render() {
        const path = window.location.pathname;
        if (DISABLED_ROUTES.includes(path)) {
            return <div/>
        }
        if (this.props.mobile) {
            return (
                <div className='footer-mobile' style={{margin: '0 -16px'}}>
                    <div className='row-plain'>
                        <div className='col-plain' style={{flex: 1}}>
                            <p className='mb-24 body2-bold white-80'>
                                GET INVOLVED
                            </p>
                            <div>
                                {FOOTER_LINKS_2.map(link =>
                                    <p className='body3 mb-20 white-50 white-100-h pointer'>
                                        {link.title}
                                    </p>
                                )}
                            </div>
                            <div className='mt-20'>
                                <p className='mb-24 body2-bold white-80'>
                                    LEGAL
                                </p>
                                <div>
                                    {FOOTER_LINKS_3.map(link =>
                                        <p className='body3 mb-20 white-50 white-100-h pointer'>
                                            {link.title}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-40 mb-60'>
                        <img src={navlogo} style={{height: 60}}/>
                        <p className='body3 white-50 mt-12'>
                            A better way to find your bud.
                        </p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='footer'>
                    <div className='footer-content'>
                        <div className='col-plain' style={{alignItems: 'flex-start'}}>
                            <img src={navlogo} style={{height: 60}}/>
                            <p className='body1 white-50 mt-12'>
                                A better way to find your bud.
                            </p>
                        </div>
                        <div className='row-plain'>
                            <div className='mr-100'>
                                <p className='mb-24 body1-bold white-100'>
                                    GET INVOLVED
                                </p>
                                <div>
                                    {FOOTER_LINKS_2.map(link =>
                                        <p className='body2 mb-20 white-50 white-100-h pointer'>
                                            {link.title}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div>
                                <p className='mb-24 body1-bold white-100'>
                                    LEGAL
                                </p>
                                <div>
                                    {FOOTER_LINKS_3.map(link =>
                                        <p className='body2 mb-20 white-50 white-100-h pointer'>
                                            {link.title}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-bottom o5 secondary-footer-logo'>
                        <img src={navlogo} style={{height: 40}}/>
                    </div>
                </div>
            )
        }
    }
}

export default Footer
