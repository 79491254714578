import React, {Component} from "react";
import phones from "../img/3phones.png";
import {motion} from "framer-motion";
import SvgThoughtBubble from "../components/svg/SvgThoughtBubble";
import SvgLightbulb from "../components/svg/SvgLightbulb";

class RecommendationsSection extends Component {
    render() {
        return (
            <div className='text-center white-100 col-ac'
                 style={{paddingTop: this.props.mobile && '12vh', marginTop: !this.props.mobile && 120}}>
                <motion.div
                    initial={{opacity: 0, transform: 'scale(.99) translateY(5%)'}}
                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                    transition={{default: {ease: "linear"}}}
                    className='relative'
                    style={{flex: 1}}>
                    {this.props.mobile &&
                    <>
                        <div className='gradient absolute o5' style={{top: '10vh', backgroundColor: '#CF5EFF05'}}/>
                        <div className='gradient absolute o5'
                             style={{right: '-5vh', backgroundColor: "#FF456105"}}/>
                    </>
                    }
                    <img src={phones}
                         className={this.props.mobile ? 'w-100p floating-sm' : 'w-75p ml-100 phones floating-sm'}/>
                </motion.div>
                <motion.div
                    initial={{opacity: 0, transform: 'scale(1) translateY(5%)'}}
                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                    transition={{default: {ease: "linear"}}}
                    style={{flex: 1, marginTop: this.props.mobile ? -30 : -80}}
                    className={this.props.mobile ? ' col-ac w-100p' : ' col-ac w-50p ml-80'}>
                    <div className='relative'>
                        <h1 className='font2 heading mb-20'
                            style={{fontSize: this.props.mobile && 32, textShadow: '0 0 40px #000000A0'}}>
                            Recommendations <br/>Catered To You
                            <span className='heading-slash' style={{fontSize: this.props.mobile && 32}}>//</span>
                        </h1>
                        <div className='absolute' style={{top: '-30%', right: '5%', transform: 'rotate(12deg)'}}>
                            <SvgLightbulb fill={'#ffffff20'}/>
                        </div>
                    </div>
                    <div className='white-80'>
                        <p className={this.props.mobile ? 'body3' : 'body1'}>
                            As you try different strains and products, <span className='highlight'>the Weed Wizard</span> learns your preferences and
                            suggests` new products that you'll love. The Weed Wizard will analyze local dispensaries to show you <span className='highlight'>exactly where to find the best bud </span>for you.
                        </p>
                    </div>
                </motion.div>
            </div>
        );
    }
}

export default RecommendationsSection;
