import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width={100}
    height={100}
    {...props}
  >
    <path d="M19 2c-5.367 0-9.658 4.293-9.885 9.604C5.487 13.665 3 17.528 3 22c0 6.615 5.385 12 12 12 2.25 0 4.311-.703 6.113-1.783.63.684 1.27 1.363 2.05 1.879C24.977 35.3 27.162 36 29.5 36c4.401 0 8.186-2.514 10.117-6.158C44.315 29.273 48 25.348 48 20.5c0-4.064-2.596-7.476-6.191-8.83C41.14 6.798 37.049 3 32 3c-2.26 0-4.284.85-5.959 2.121C24.225 3.268 21.793 2 19 2zm0 2a7.973 7.973 0 0 1 6.203 2.95l.666.816.781-.707A7.926 7.926 0 0 1 32 5a7.99 7.99 0 0 1 7.98 7.434l.05.685.657.201A7.503 7.503 0 0 1 46 20.5a7.491 7.491 0 0 1-7.063 7.49l-.591.033-.254.538A9.495 9.495 0 0 1 29.5 34a9.433 9.433 0 0 1-5.234-1.572 9.541 9.541 0 0 1-2.202-2.02l-.584-.734-.77.537A9.941 9.941 0 0 1 15 32C9.465 32 5 27.535 5 22c0-3.9 2.226-7.265 5.457-8.916l.559-.285-.014-.627A7.983 7.983 0 0 1 19 4zm1 32c-2.75 0-5 2.25-5 5s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5zm0 2c1.669 0 3 1.331 3 3s-1.331 3-3 3-3-1.331-3-3 1.331-3 3-3zM9 40c-2.197 0-4 1.803-4 4s1.803 4 4 4 4-1.803 4-4-1.803-4-4-4zm0 2c1.116 0 2 .884 2 2s-.884 2-2 2-2-.884-2-2 .884-2 2-2z" />
  </svg>
)

export default SvgComponent
