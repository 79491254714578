import './App.css';
import './styles/Formatting.css'
import './styles/Colors.css'
import './styles/Text.css'
import React, {Component} from "react";
import HeroSection from "./Sections/HeroSection";
import Navbar from "./components/Navbar";
import RecommendationsSection from "./Sections/RecommendationsSection";
import Section3 from "./Sections/ProfileSection";
import ExperiencesSection from "./Sections/ExperiencesSection";
import MobileApp from "./MobileApp";
import Footer from "./Sections/Footer";
import GradeSection from "./Sections/GradeSection";

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scrollPosition: 0,
            mobile: window.innerWidth <= 760,
            scrollHighlight: 1,
        }
        this.section1Ref = React.createRef();
        this.section2Ref = React.createRef();
        this.section3Ref = React.createRef();
        this.mintSectionRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
        window.addEventListener("resize", this.resize.bind(this));

        this.resize();
    }

    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
        window.removeEventListener("resize", this.resize.bind(this));
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop
        this.setState({
            scrollPosition: winScroll,
        })
        if (winScroll > this.section1Ref.current.offsetTop && winScroll < (this.mintSectionRef.current.offsetTop - 700)) {
            this.setState({scrollHighlight: 1})
        }
        if (winScroll > (this.mintSectionRef.current.offsetTop - 700) && winScroll < this.section2Ref.current.offsetTop - 700) {
            this.setState({scrollHighlight: 2})
        }
        if (winScroll > (this.section2Ref.current.offsetTop - 700) && winScroll < this.section3Ref.current.offsetTop - 700) {
            this.setState({scrollHighlight: 3})
        }
        if (winScroll > this.section3Ref.current.offsetTop - 700) {
            this.setState({scrollHighlight: 4})
        }
    }

    scrollToMint = () => {
        this.mintSectionRef.current.scrollIntoView({behavior: 'smooth'})
    }

    render() {
        if (this.state.mobile) {
            return (
                <MobileApp scrollPosition={this.state.scrollPosition}/>
            )
        }
        return (
            <div className='row-je relative' style={{height: '100vh'}}>
                <Navbar scrollPosition={this.state.scrollPosition}/>
                {/*<div style={{alignSelf:'flex-end'}} className='w-60p mb-100 ml-80'>*/}
                {/*    <img src={azukigirl} className='w-70p azukigirl'/>*/}
                {/*</div>*/}
                <div className='mr-80 text-right relative'>
                    <div className='col-jc' style={{minHeight: '100vh'}} ref={this.section1Ref}>
                        <HeroSection scrollToMint={this.scrollToMint} scrollPosition={this.state.scrollPosition}/>
                    </div>
                    <div className='col-jc' style={{minHeight: '90vh'}} ref={this.mintSectionRef}>
                        <RecommendationsSection/>
                    </div>
                    <div className='col-jc' style={{minHeight: '90vh'}} ref={this.section2Ref}>
                        <ExperiencesSection scrollPosition={this.state.scrollPosition}
                                            offsetTop={this.mintSectionRef.current?.offsetTop}/>
                    </div>
                    <div className='col-jc' style={{minHeight: '90vh'}}>
                        <GradeSection scrollPosition={this.state.scrollPosition}
                                     offsetTop={this.mintSectionRef.current?.offsetTop}/>
                    </div>
                    <div className='col-jc' style={{minHeight: '90vh'}} ref={this.section3Ref}>
                        <Section3/>
                    </div>
                    <Footer/>
                </div>
                <div className='col-ac-jc fixed' style={{top: 0, bottom: 0, right: 40}}>
                    <div
                        className={`sidebar-progress-indicator filter ${this.state.scrollHighlight < 2 && 'bg-gradient-hotpink'}`}
                        style={{
                            transform: this.state.scrollHighlight === 1 && 'scale(1.02)',
                            boxShadow: this.state.scrollHighlight === 1 && '0 0 20px #DB437Ac8'
                        }}
                    />
                    <div
                        className={`sidebar-progress-indicator ${this.state.scrollHighlight === 2 && 'bg-gradient-hotpink'}`}
                        style={{
                            transform: this.state.scrollHighlight === 2 && 'scale(1.02)',
                            boxShadow: this.state.scrollHighlight === 2 && '0 0 20px #DB437Ac8'
                        }}
                    />
                    <div
                        className={`sidebar-progress-indicator ${this.state.scrollHighlight === 3 && 'bg-gradient-hotpink'}`}
                        style={{
                            transform: this.state.scrollHighlight === 3 && 'scale(1.02)',
                            boxShadow: this.state.scrollHighlight === 3 && '0 0 20px #DB437Ac8'
                        }}
                    />
                    <div
                        className={`sidebar-progress-indicator ${this.state.scrollHighlight === 4 && 'bg-gradient-hotpink'}`}
                        style={{
                            transform: this.state.scrollHighlight === 4 && 'scale(1.02)',
                            boxShadow: this.state.scrollHighlight === 4 && '0 0 20px #DB437Ac8'
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default App;
