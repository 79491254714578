import React, {Component} from "react";

class Navbar extends Component {
    render() {
        const shrinkNav = (this.props.scrollPosition > 30)
        return (
            <nav
                className={shrinkNav && 'nav-minimized'}
                style={{
                    left: 0, right: 0, zIndex: 999, padding: this.props.mobile ? '16px 16px' : '20px 40px',
                }}>
                <div className='row-ac relative' style={{marginTop: 5}}>
                </div>
                <div className='row-ac'>
                    <div className='body2 gradient-hotpink ph-20 pv-8 pointer o65 o1-h mt-4'
                         style={{fontWeight: 800, marginRight: !this.props.mobile && 8}}>
                        CONTACT
                    </div>
                    <img alt={'logo'} src={require('../img/brand/logo/icon.png')} style={{height:this.props.mobile ? 44 : 50}}/>
                    {/*<div className='logo-container'>*/}
                    {/*    WeedWise*/}
                    {/*</div>*/}
                </div>
            </nav>
        );
    }
}

export default Navbar;
