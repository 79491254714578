import React, {Component} from "react";
import {Buffer} from 'buffer';
import {motion} from "framer-motion"
import StrainMemoryCard from "../components/StrainMemoryCard";
import MemoryCard from "../components/MemoryCard";
import SvgThoughtBubble from "../components/svg/SvgThoughtBubble";

window.buffer = Buffer;

class ExperiencesSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }



    render() {
        const startAnimate = (this.props.scrollPosition > this.props.offsetTop - (document.body.clientHeight / 2))
        return (
            <div
                className={this.props.mobile ? 'text-right col-ac white-100 bg-black2' : 'text-right row-ac white-100 bg-black2'}
                style={{
                    paddingTop: this.props.mobile && '0vh',
                    padding: this.props.mobile ? '40px 16px 15vh 16px' : '140px 80px 140px 0',
                    marginRight: !this.props.mobile ? -80 : -16,
                    marginTop: '10vh',
                    marginLeft: this.props.mobile && -16,
                    flexDirection: !this.props.mobile && 'row-reverse'
                }}>
                <motion.div
                    initial={{opacity: 0, transform: 'scale(.985) translateY(5%)'}}
                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                    transition={{default: {ease: "linear"}}}
                    style={{flex: 1}}
                    className='col-ac relative '
                >
                    <div className='absolute' style={{top: '20%', right: '5%'}}>
                        <SvgThoughtBubble fill={'#ffffff20'}/>
                    </div>
                    <StrainMemoryCard mobile={this.props.mobile} className={'ml-0'}/>
                    <MemoryCard
                        mobile={this.props.mobile}
                        style={{
                            marginLeft: !this.props.mobile ? 220 : 0,
                            width: this.props.mobile ? '80%' : "50%",
                            marginTop: -16,
                            position: 'relative',
                            zIndex: 0
                        }}/>
                </motion.div>
                <div style={{flex: 1}} className={!this.props.mobile ? 'ml-100' : 'text-center'}>
                    <>
                        <motion.h1
                            initial={{opacity: 0, transform: 'scale(1) translateY(40px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}}}
                            className='font2 heading mb-20'
                            style={{fontSize: this.props.mobile && 32, textShadow: '0 0 40px #000000A0'}}>
                            TRACK YOUR<br/>Smoke SESSIONS
                            <span className='heading-slash' style={{fontSize: this.props.mobile && 32}}>//</span>
                        </motion.h1>
                        <motion.p
                            initial={{opacity: 0, transform: 'scale(1) translateY(40px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}}}
                            className={this.props.mobile ? 'body3 mv-0 white-80' : 'body1 mv-0 white-80'}>
                                Track the products you use, effects, quality of the high, and any other characteristics that matter to you.<span
                                className='highlight'>The Weed Wizard </span> will automatically adjust its recommendations based on your reviews to keep sourcing the best bud for you.
                        </motion.p>
                    </>
                </div>
            </div>
        );
    }
}

export default ExperiencesSection;

