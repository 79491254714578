import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width={100}
    height={100}
    {...props}
  >
    <path d="M25 1a1 1 0 0 0-.932.639l-6.166 15.896L.95 18.4a1 1 0 0 0-.582 1.774l13.201 10.793-4.336 16.377a1 1 0 0 0 1.508 1.097L25 39.29l14.26 9.152a1 1 0 0 0 1.508-1.097l-4.336-16.377 13.2-10.793a1 1 0 0 0-.581-1.774l-16.953-.865L25.932 1.64A1 1 0 0 0 25 1zm0 3.764 5.467 14.097a1 1 0 0 0 .883.637l15.01.768-11.692 9.56a1 1 0 0 0-.334 1.03l3.842 14.513-12.635-8.111a1 1 0 0 0-1.082 0l-12.635 8.111 3.842-14.514a1 1 0 0 0-.334-1.029l-11.691-9.56 15.01-.768a1 1 0 0 0 .882-.637L25 4.764z" />
  </svg>
)

export default SvgComponent
