import React, {PureComponent} from "react";
import flower from "../img/flower.png";
import SvgStarFilled from "./svg/SvgStarFilled";
import SvgStar from "./svg/SvgStar";
import SvgEdible from "./svg/SvgEdible";
import HoverRatings from "./HoverRatings";

class MemoryCard extends PureComponent {
    render() {
        return (
            <div className={`memory-card col-plain ${this.props.className}`} style={this.props.style}>
                <div style={{
                    backgroundColor: "#3561f0",
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    padding: '8px 20px 8px 20px'
                }}>
                    <div className='row-ac'>
                        <div className='body3-bold white-50 mr-8'>
                            Hybrid
                        </div>
                        <div className='body3 white-50'>
                            THC 18%
                        </div>
                    </div>
                    <div className='body2-bold'>
                        OG Gelato
                    </div>
                </div>
                <div className='ph-20 pv-16'>
                    <div className='row-ac-jb mb-8 w-100p'>
                        <div className='row-ac'>
                            <div className='bg-white-20 br-48 col-ac-jc' style={{width: 48, height: 48}}>
                                <SvgEdible fill={'#FFFFFF'} width={36} height={36}/>
                            </div>
                            <HoverRatings mobile={this.props.mobile}/>
                        </div>
                        <div className='body3 white-50'>
                            2 days ago
                        </div>
                    </div>
                    <div className='body1 col-ac'>
                        Loved the taste of the gelato Punch Chocolate. Kicks in a lot faster than other edibles I've
                        tried and 10mg kept me at a good level for 3-4hrs.
                    </div>
                </div>
            </div>
        )
    }
}

export default MemoryCard
