import React, {PureComponent} from "react";
import flower from "../img/flower.png";
import SvgChevronRight from "./svg/SvgChevronRight";

class StrainMemoryCard extends PureComponent {
    render() {
        return (
            <div className={`strain-card row-jb ${this.props.className}`} style={{width:this.props.mobile ? '80%' : '50%', marginTop:this.props.mobile && 100}}>
                <div className='col-jb' style={{alignItems: 'stretch'}}>
                    <div>
                        <div className='row-ac'>
                            <div
                                className={this.props.mobile ? 'body3-bold white-50 mr-12' : 'body1-bold white-50 mr-12'}>
                                Indica
                            </div>
                            <div className={this.props.mobile ? 'body3 black-70' : 'body1 black-70'}>
                                THC 18%
                            </div>
                        </div>
                        <div className={this.props.mobile ? 'title4' : 'title3'}>
                            Sour&nbsp;Bubble
                        </div>
                    </div>
                    <div className='row-ac'>
                        <div className={this.props.mobile ? 'body1-bold mr-4' : 'title4 mr-4'}>
                            3 sessions
                        </div>
                        <SvgChevronRight fill={'#FFFFFF'} width={16} height={16}/>
                    </div>
                </div>
                <img src={flower} className={'w-60p'}
                     style={{margin: this.props.mobile ? '-60px -50px -20px 0px' :  '-80px -50px -60px 0px', alignSelf: "flex-end", minWidth: this.props.mobile ? 120 : 200}}/>
            </div>
        )
    }
}

export default StrainMemoryCard
