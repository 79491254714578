import React, {PureComponent} from "react";
import flower from "../img/flower.png";
import SvgStarFilled from "./svg/SvgStarFilled";
import SvgStar from "./svg/SvgStar";
import SvgMagicWeed from "./svg/SvgMagicWeed";
import SvgLightbulb from "./svg/SvgLightbulb";
import SvgBolt from "./svg/SvgBolt";

class GradeWeedWidget extends PureComponent {
    state = {
        hovering: false
    }

    onHover = (index) => {
        this.setState({hovering: index})
    }

    onMouseLeave = () => {
        this.setState({hovering: false})
    }

    switchColor = () => {
        switch (this.state.hovering) {
            case 1:
                return '#4BB24520'
            case 2:
                return '#4BB24560'
            case 3:
                return '#4BB245C0'
            case 4:
                return '#4BB245D0'
            case 5:
                return '#4BB245'
            default:
                return '#FFFFFF20'
        }
    }

    switchRating = (type) => {
        switch (this.state.hovering) {
            case 1:
                return type === 'a' ? '1%' : type === 'b' ? '3%' : '5%'
            case 2:
                return type === 'a' ? '18%' : type === 'b' ? '22%' : '20%'
            case 3:
                return type === 'a' ? '46%' : type === 'b' ? '40%' : '32%'
            case 4:
                return type === 'a' ? '66%' : type === 'b' ? '60%' : '54%'
            case 5:
                return type === 'a' ? '78%' : type === 'b' ? '86%' : '72%'
            default:
                return '0%'
        }
    }

    render() {
        const starSize = this.props.mobile ? 32 : 60
        return (
            <div className={this.props.mobile ? 'col-ac w-100p mt-100' : 'col-ac w-100p ml-80'}
                 onMouseEnter={() => this.onHover(1)} onMouseLeave={this.onMouseLeave}>
                <SvgMagicWeed fill={this.switchColor()} width={this.props.mobile ? '80%' : '60%'}
                              height={this.props.mobile ? '80%' : '60%'}
                              style={{transition: '400ms ease'}}/>
                <div className={`row-ac-jc ml-12 ${this.props.mobile && 'mt-20'}`} onMouseLeave={this.onMouseLeave}>
                    <div onMouseEnter={() => this.onHover(1)}>
                        {this.state.hovering ?
                            (
                                (this.state.hovering >= 1) ?
                                    <SvgStarFilled width={starSize} height={starSize} fill={'#FFFFFF'}
                                                   className={'mr-4'}/>
                                    :
                                    <SvgStarFilled width={starSize} height={starSize} fill={'#FFFFFF'}
                                                   className={'mr-4'}/>
                            )
                            :
                            <SvgStar width={starSize} height={starSize} fill={'#FFFFFF50'} className={'mr-4'}/>
                        }
                    </div>
                    <div onMouseEnter={() => this.onHover(2)}>
                        {!!this.state.hovering ?
                            (
                                (this.state.hovering >= 2) ?
                                    <SvgStarFilled width={starSize} height={starSize} fill={'#FFFFFF'}
                                                   className={'mr-4'}/>
                                    :
                                    <SvgStar width={starSize} height={starSize} fill={'#FFFFFF'} className={'mr-4'}/>
                            )
                            :
                            <SvgStar width={starSize} height={starSize} fill={'#FFFFFF50'} className={'mr-4'}/>
                        }
                    </div>
                    <div onMouseEnter={() => this.onHover(3)}>
                        {!!this.state.hovering ?
                            (
                                (this.state.hovering >= 3) ?
                                    <SvgStarFilled width={starSize} height={starSize} fill={'#FFFFFF'}
                                                   className={'mr-4'}/>
                                    :
                                    <SvgStar width={starSize} height={starSize} fill={'#FFFFFF'} className={'mr-4'}/>
                            )
                            :
                            <SvgStar width={starSize} height={starSize} fill={'#FFFFFF50'} className={'mr-4'}/>
                        }
                    </div>
                    <div onMouseEnter={() => this.onHover(4)}>
                        {!!this.state.hovering ?
                            (
                                (this.state.hovering >= 4) ?
                                    <SvgStarFilled width={starSize} height={starSize} fill={'#FFFFFF'}
                                                   className={'mr-4'}/>
                                    :
                                    <SvgStar width={starSize} height={starSize} fill={'#FFFFFF'} className={'mr-4'}/>
                            )
                            :
                            <SvgStar width={starSize} height={starSize} fill={'#FFFFFF50'} className={'mr-4'}/>
                        }
                    </div>
                    <div onMouseEnter={() => this.onHover(5)}>
                        {!!this.state.hovering ?
                            (
                                (this.state.hovering >= 5) ?
                                    <SvgStarFilled width={starSize} height={starSize} fill={'#FFFFFF'}
                                                   className={'mr-4'}/>
                                    :
                                    <SvgStar width={starSize} height={starSize} fill={'#FFFFFF'} className={'mr-4'}/>
                            )
                            :
                            <SvgStar width={starSize} height={starSize} fill={'#FFFFFF50'} className={'mr-4'}/>
                        }
                    </div>
                </div>
                <div className={this.props.mobile ? 'w-80p mt-32 col-ac mb-32' : 'w-50p mt-32 col-ac'}>
                    <div className='white-80 mb-8 body2-bold'>
                        Structure
                    </div>
                    <div className='w-100p br-32 bg-white-30 relative mb-28' style={{height: 8}}>
                        {/*<div className='absolute' style={{top: '-80px', right: '-22%', transform: 'rotate(12deg)'}}>*/}
                        {/*    <SvgBolt fill={'#ffffff20'} width={80} height={80}/>*/}
                        {/*</div>*/}
                        <div className='br-32 absolute'
                             style={{
                                 height: 12,
                                 backgroundColor: this.state.hovering ? 'green' : '#9e9e9e',
                                 width: 60,
                                 top: -2,
                                 transition: '500ms ease',
                                 left: this.switchRating('a')
                             }}/>
                    </div>
                    <div className='white-80 mb-8 body2-bold'>
                        Frostiness
                    </div>
                    <div className='w-100p br-32 bg-white-30 relative mb-28' style={{height: 8}}>
                        <div className='br-32 absolute'
                             style={{
                                 height: 12,
                                 backgroundColor: this.state.hovering ? 'green' : '#9e9e9e',
                                 width: 60,
                                 top: -2,
                                 transition: '500ms ease',
                                 left: this.switchRating('b')
                             }}/>
                    </div>
                    <div className='white-80 mb-8 body2-bold'>
                        Color
                    </div>
                    <div className='w-100p br-32 bg-white-30 relative' style={{height: 8}}>
                        <div className='br-32 absolute'
                             style={{
                                 height: 12,
                                 backgroundColor: this.state.hovering ? 'green' : '#9e9e9e',
                                 width: 60,
                                 top: -2,
                                 transition: '500ms ease',
                                 left: this.switchRating('b')
                             }}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default GradeWeedWidget
