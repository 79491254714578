import React, {PureComponent} from "react";
import flower from "../img/flower.png";
import SvgStarFilled from "./svg/SvgStarFilled";
import SvgStar from "./svg/SvgStar";

class HoverRatings extends PureComponent {
    state = {
        hovering: false
    }

    onHover = (index) => {
        this.setState({hovering: index})
    }

    onMouseLeave = () => {
        this.setState({hovering: false})
    }

    render() {
        const iconSize = this.props.mobile ? 16 : 20
        return (
            <div className='row-ac-jc ml-12' onMouseLeave={this.onMouseLeave}>
                <div onMouseEnter={() => this.onHover(1)}>
                    {this.state.hovering ?
                        (
                            (this.state.hovering >= 1) ?
                                <SvgStarFilled width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                                :
                                <SvgStarFilled width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                        )
                        :
                        <SvgStarFilled width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                    }
                </div>
                <div onMouseEnter={() => this.onHover(2)}>
                    {!!this.state.hovering ?
                        (
                            (this.state.hovering >= 2) ?
                                <SvgStarFilled width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                                :
                                <SvgStar width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                        )
                        :
                        <SvgStarFilled width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                    }
                </div>
                <div onMouseEnter={() => this.onHover(3)}>
                    {!!this.state.hovering ?
                        (
                            (this.state.hovering >= 3) ?
                                <SvgStarFilled width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                                :
                                <SvgStar width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                        )
                        :
                        <SvgStarFilled width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                    }
                </div>
                <div onMouseEnter={() => this.onHover(4)}>
                    {!!this.state.hovering ?
                        (
                            (this.state.hovering >= 4) ?
                                <SvgStarFilled width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                                :
                                <SvgStar width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                        )
                        :
                        <SvgStarFilled width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                    }
                </div>
                <div onMouseEnter={() => this.onHover(5)}>
                    {!!this.state.hovering ?
                        (
                            (this.state.hovering >= 5) ?
                                <SvgStarFilled width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                                :
                                <SvgStar width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                        )
                        :
                        <SvgStar width={iconSize} height={iconSize} fill={'#FFFFFF'} className={'mr-4'}/>
                    }
                </div>
            </div>
        )
    }
}

export default HoverRatings
