import React, {Component} from "react";
import LOGO from '../img/brand/logo/logo.png'
import wizard from "../img/brand/logo/wizard.png";
import {motion} from "framer-motion";

class HeroSection extends Component {
    render() {
        return (
            <div className={`text-right ${this.props.mobile ? '' : 'row-ac'}`}
                 style={{paddingTop: this.props.mobile && '12vh'}}>
                {this.props.mobile &&
                <div className='col-ac'>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .4}}
                        className={this.props.mobile ? 'relative col-ae' : 'relative col-ae'}>
                        <img alt='logo' className={this.props.mobile ? 'relative' : 'relative azukigirl'} src={LOGO}
                             style={{width: this.props.mobile ? '40vw' : 600, zIndex: 1}}/>
                    </motion.div>
                </div>
                }
                <div className='relative' style={{flex: 1}}>
                    <div className='gradient absolute' style={{top: '10vh'}}/>
                       <div className='shine-gradient absolute'/>
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(.98) translateY(12px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear", delay: .2}}}
                        style={{flex: 1}} className='col-plain relative'>
                        <div className='col-ac' style={{
                            transform: `translateX(-${this.props.scrollPosition / 12}px)`
                            // filter: `drop-shadow(-292px -${(this.props.scrollPosition * .5) + 20}px 180px rgba(207, 94, 255, 0.5)) drop-shadow(129px ${(this.props.scrollPosition * .1)}px 50px rgba(255, 69, 97, 0.31)) drop-shadow(10px 10px 10px rgba(12, 2, 3, 0.31))`
                        }}>
                            <img src={wizard} className='w-90p floating border-animation'
                                 style={{marginLeft: this.props.mobile && 0}}
                            />
                        </div>
                    </motion.div>
                </div>
                <div style={{flex: 1}} className={this.props.mobile && 'text-center'}>
                    {!this.props.mobile &&
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "linear"}, delay: .4}}
                        className={this.props.mobile ? 'relative mv-32' : 'relative col-ae'}>
                        <img alt='logo' className='mt-32 relative azukigirl' src={LOGO}
                             style={{width: this.props.mobile ? '80vw' : '30vw', zIndex: 1}}/>
                    </motion.div>
                    }
                    <motion.div
                        initial={{opacity: 0, transform: 'scale(1) translateY(5%)'}}
                        whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                        transition={{default: {ease: "anticipate"}, delay: .5}}
                    >
                        <h1 className='font2 heading mb-20 white-100' style={{fontSize: this.props.mobile && 32}}>
                            A BETTER WAY<br/> TO FIND AND RATE YOUR BUD.
                            <span className='heading-slash' style={{fontSize: this.props.mobile && 32}}>//</span>
                        </h1>
                        <p className={this.props.mobile ? 'body3 white-80' : 'body1 white-80'}>
Meet the <span
                            className='highlight'>Weed Wizard</span>, an AI-powered bud recommender that helps find you the best bud for you in real-time.
                        </p>
                        {!this.props.mobile &&
                        <div className='button ml-a' onClick={this.props.scrollToMint}>
                            Learn More
                        </div>
                        }
                    </motion.div>
                </div>
            </div>
        );
    }

}

export default HeroSection;
