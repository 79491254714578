import React, {Component} from "react";
import {Buffer} from 'buffer';
import {motion} from "framer-motion"
import GradeWeedWidget from "../components/GradeWeedWidget";

window.buffer = Buffer;

class GradeSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            walletAddress: '',
            connected: false,
            mintSuccessModal: false,
            mode: "metamask",
            batchSize: 1,
            mintSuccess: false,
            pctMinted: 0,
            numMinted: 0,
            loading: false
        }
    }


    render() {
        const startAnimate = (this.props.scrollPosition > this.props.offsetTop - (document.body.clientHeight / 2))
        return (
            <div className='text-right row-ac white-100' style={{
                flexDirection: this.props.mobile ? 'column-reverse' : 'row-reverse',
            }}>
                <div style={{flex: 1}} className={!this.props.mobile ? 'ml-100' : 'text-center'}>
                    <>
                        <motion.h1
                            initial={{opacity: 0, transform: 'scale(1) translateY(40px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}}}
                            className='font2 heading mb-20'
                            style={{fontSize: this.props.mobile && 32, textShadow: '0 0 40px #000000A0'}}>
                            GRADE YOUR FLOWER
                            <span className='heading-slash' style={{fontSize: this.props.mobile && 32}}>//</span>
                        </motion.h1>
                        <motion.p
                            initial={{opacity: 0, transform: 'scale(1) translateY(40px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}}}
                            className={this.props.mobile ? 'body3 mv-0 white-80' : 'body1 mv-0 white-80'}>
                                With a quick scan of your bud via phone camera, Weed Wise uses its proprietary algorithm
                                to analyse the quality of your bud by <span className="highlight">structure, color, and frostiness </span>and rate it
                                according to the Brulee grading system to ensure you're only using the best product.
                        </motion.p>
                    </>
                </div>
                <motion.div
                    initial={{opacity: 0, transform: 'scale(.985) translateY(5%)'}}
                    whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                    transition={{default: {ease: "linear"}}}
                    style={{flex: 1}}
                >
                    <GradeWeedWidget mobile={this.props.mobile}/>
                    {/*<img src={azukigirl} className='w-90p mt-100 phones'/>*/}
                </motion.div>
            </div>
        );
    }
}

export default GradeSection;

