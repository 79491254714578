export const USERS = [
    {
        name: 'Conventions'
    },
    {
        name: 'Concerts'
    },
    {
        name: 'Festivals'
    }
]


export const FOOTER_LINKS_1 = [
    {
        title: 'Twitter'
    },
    {
        title: 'Discord'
    },
    {
        title: 'Instagram'
    },
    {
        title: 'LinkedIn'
    },
    {
        title: 'TikTok'
    },
    {
        title: 'Facebook'
    }
]

export const FOOTER_LINKS_2 = [
    {
        title: 'FAQ'
    },
    {
        title: 'Contact Us'
    },
    {
        title: 'Suggestions'
    }
]

export const FOOTER_LINKS_3 = [
    {
        title: 'Terms of Service'
    },
    {
        title: 'Privacy Policy'
    }
]
